<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="productlistcard" :loading="loading">
          <h2 v-if="!!!itemList">Keine Produkte gefunden</h2>
          <v-data-table
            :headers="headers"
            :items="itemList"
            :search="search"
            :itemsPerPage="-1"
            multi-sort
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Produktsuche"
                class="pa-4"
                :outlined="true"
                :filled="true"
                :clearable="true"
                background-color="#e8eff7"
              ></v-text-field>
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="askDeleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Produkt löschen?</v-card-title>
        <v-card-text>
          Soll das Produkt
          <strong>{{ selectedItem.name }}</strong> wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteItem">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "ProductList",
  data: () => ({
    loading: false,
    search: "",
    dlgDelete: false,
    selectedItem: false,
    headers: [
      { text: "Produktnr", value: "productnr" },
      { text: "Name", value: "name" },
      { text: "Netto", value: "netto" },
      { text: "Ust", value: "ust" },
      { text: "Brutto", value: "brutto" },

      { text: "Actions", value: "actions", sortable: false }
    ]
  }),
  components: {},
  methods: {
    askDeleteItem(item) {
      this.dlgDelete = true;
      this.selectedItem = item;
    },
    editItem(item) {
      this.$router.push({ name: "product-edit", params: { id: item._id } });
    },
    deleteItem() {
      this.dlgDelete = false;
      this.$store
        .dispatch("product/deleteItem", this.selectedItem)
        .then((result) => {
          if (result) {
            this.$notify({
              group: "dataline",
              type: "info",
              title: "Produkt gelöscht",
              text: "Produkt " + this.selectedItem.name + " wurde gelöscht"
            });
          }
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: "Fehler beim löschen des Produktes. " + error.message
            });
          }
        });
    }
  },

  computed: {
    itemList() {
      return this.$store.getters["product/getItemList"];
    }
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("product/fetchItemList")
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        }
      });
  }
};
</script>

<style scoped>
#produktlistcard {
  margin: 20px;
}
</style>
