<template>
  <v-container>
    <v-col cols="12" sm="12" offset-sm="0">
      <v-card class="maincard">
        <v-container>
          <v-row align="center">
            <v-col cols="12" xs="12" sm="6">
              <v-card-title>Produktverwaltung</v-card-title>
            </v-col>
            <v-col cols="12" xs="12" sm="3" style="text-align: right;">
              <ImportDialog
                importUrl="/product/import"
                :mapFields="mapFields"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="3" style="text-align: right;">
              <v-btn to="/product/edit" color="success">
                <v-icon style="margin-right: 10px;">mdi-account-plus</v-icon>
                <span>Neues Produkt</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <ProductList />
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ProductList from "@/components/products/ProductList";
import ImportDialog from "@/components/products/ProductImportDialog";

export default {
  name: "ProductMgmt",
  data: () => ({
    mapFields: {
      productnr: "Produktnr",
      name: "Name",
      description: "Beschreibung",
      purchasing_price: "Einkaufspreis",
      surcharge: "Aufschlag",
      ust: "MwSt.",
      netto: "VK Netto",
      brutto: "VK Brutto",
      unit: "Einheit",
      productlink: "Produkt Weblink",
    },
  }),
  components: { ProductList, ImportDialog },
  methods: {},
  computed: {},
};
</script>
<style scoped></style>
